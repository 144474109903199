:root{
  --primary-color: #48887b ;
  
}

body{
  font-family: 'PT Sans', sans-serif !important;
}

.sc-text-muted {
  --bs-text-opacity: 1;
  color: #808589bf !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 769px) {

  .full-height {
    height: 100vh;
  }

}

.hm-cols {
  display: flex;
  align-items: center;
  justify-content: center;
}

.b-primary{
  background-color: #48887b !important;
  border-color: #48887b !important;
}

#comment-box{

  display: block;
    background: #ededed;
    border-width: 0px !important;
}

#mn-div{

  width: 380px;
  
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%; /* Makes the image circular */
}



.profile-name {
  font-size: 20px;
  font-weight: bold;
  color: var(--primary-color);
}

.profile-fullname {
  font-size: 24px;
  font-weight: 800;
}

.profile-bio {
  margin-top: 20px;
}

.profile-stats {
  margin-top: 20px;
  display: flex;
  align-items: center; /* Align items horizontally */
}

.mt-2 p{
  text-align: justify;
} 

.web-btn{

  background: #48887b;
  padding: 2px 12px;
  border-radius: 50px;
  color: white;

}


@media (max-width: 768px) {

  #myTabs{
    font-size: smaller;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }

 .mt-2 p,.followers, .following, .comment-content{
   font-size: small;
 } 

.profile-name {
  font-size: 15px;
}

.profile-fullname {
  font-size: 20px;
  font-weight: 800;
}

.profile-bio {
  margin-top: 15px;
  font-size: small;
}

.profile-stats {
  margin-top: 15px;
  display: flex;
  align-items: center; /* Align items horizontally */
}

  .tg-btn, .edit-profile-btn{

    font-size: small;
  }

  .tg-btn{

    width: 150px;
    display: inline-block;
  }


  .text-div p, .text-div h2, .text-div h3, .text-div h4, .text-div h5, .text-div h6, .text-div ul {
    font-size: small;
  }

  #mn-div{
    width: 200px;
    padding: 15px 0;
    
  }

  #comment-box{
    bottom: 40px;
    }

  .hm-logo{
    width: 50%;
  }

  .bible-verses{

    display: none;
  }

}


/* App.css */

.pt-sans-regular {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.pt-sans-bold {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.pt-sans-regular-italic {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.pt-sans-bold-italic {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-style: italic;
}




.profile-container {
  display: flex;
  flex-direction: column;
  padding: 24px 12px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}




.followers, .following {
  margin-right: 20px;
}

.edit-profile-btn {
  padding: 7px 14px;
  background-color: white;
  color: #48887b;
  border: 1px solid #48887b;
  border-radius: 14px;
  cursor: pointer;
}

.ep-form input, .ep-form input:focus {
  border: unset;
  box-shadow: unset;
}

.ep-form textarea, .ep-form textarea:focus {
  border: unset;
  box-shadow: unset;
}

label{
  font-size: 10px;
}

/* LoadingScreen.css */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white background */
  z-index: 9999; /* Ensure it covers all other content */
}


.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  min-height: 300px;
}


#comment-input, #comment-input:focus{

  border: none;
  min-height: 70px;
  box-shadow: none;

}
.nav-tabs {
  display: flex;
}

.nav-link {
  flex: 1;
  text-align: center;
  color: grey !important;
}

.nav-link-selected {

  color: var(--primary-color) !important;
}

.menu-icon {
  font-size: 21px;
}


.text-justify{
  text-align: justify;
}

.nav-item > button {
  border: none;
  text-decoration: none;
  color: black;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-width: 7px;
  border-bottom-style: solid;
  border-bottom-color:#48887b !important;
}


 .nav-link:hover {
  color: #48887b !important;
  border: none;
}


.floating-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000; /* Ensure the button appears above other elements */
}

.floating-button > i {
  font-size: 24px;
}

@media (min-width: 768px) {
  /* Adjust position for larger screens */
  .floating-button {
    bottom: 20px;
  }

  .insight-icons-div{
    width: 50%;
  }
}

.btn-close{
  font-size: x-small;
}

.refs-div{
  padding: 12px;
  border: 2px solid var(--primary-color);
  border-radius: 14px;
}

.form-inline {
  display: flex;
  align-items: center;
}

.form-group {
  margin-bottom: 0;
}

.rounded-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-group > .form-control {
  font-size: small;
  min-width: 40px;
}


/* App.css */

body {
  transition: background-color 0.3s, color 0.3s;
}

body.dark-mode {
  background-color: #121212 !important;
  color: #ffffff !important;
}

body.dark-mode  .modal-content {
  background-color:  #121212 !important;
  color: #ffffff;
}

body.dark-mode .btn-outline-dark {
  border-color: white !important;
  color: #ffffff !important;
}

body.dark-mode  .btn-close {
  background-color:  #fff !important;
}

body.dark-mode  .nav-link {
  background-color:  #121212 !important;
  color: #ffffff !important;
}

body.dark-mode .comment-content{
  background-color:  #1e1c1c !important;
}


.sh-btn{

  font-size: smaller !important;
}

.txt-btn{

  font-size: smaller !important;
}

body.dark-mode  .txt-btn {

  color: #ffffff !important;
}



body.dark-mode .edit-profile-btn  {
  background-color:  #121212 !important;
  color: #ffffff;
  border-color: white !important;
}

body.dark-mode .navbar, body.dark-mode footer , body.dark-mode input, body.dark-mode textarea  {
  background-color:  #121212 !important;
  color: #ffffff;
}


 body.dark-mode .form-control  {
  background-color:  #121212 !important;
  color: #ffffff;
  border-color: #121212 !important;
}


.modal-header{

  border: none !important;
}

body.dark-mode .form-control:focus {
  color: white !important;
  border-color: unset;
  border-width: 0px;
  box-shadow: none;
}

.form-control:focus {
  border-color: unset !important;
  border-width: 0px !important;
  box-shadow: none !important;
}


/* Skeleton.css */

.skeleton-post {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.skeleton-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ddd;
  margin-right: 20px;
}

.skeleton-content {
  flex: 1;
}

.skeleton-line {
  height: 15px;
  background: #ddd;
  margin-bottom: 10px;
  border-radius: 4px;
}

.skeleton-line.short {
  width: 70%;
}


.ref-btn{

    cursor: default;

}


.search-component {
  width: 100%;
  max-width: 400px;
  margin: auto;
  position: relative;
}

.search-box {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 8px;
}

.search-icon {
  margin-right: 8px;
  color: #888;
}

.search-box input {
  max-width: 80%;
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
}

.results {
  margin-top: 10px;
  padding: 10px;
  border: 0px solid #ccc;
  border-radius: 7px;
  max-height: 60vh;
  overflow-y: scroll;
}

.results p {
  margin: 0;
}

.results ul {
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;
}

.results li {
  padding: 5px 0;
}

.error {
  color: red;
  margin-top: 10px;
}


    /* Hide default scrollbar */
    ::-webkit-scrollbar {
      width: 1px;
  }
  /* Style the scrollbar track */
  ::-webkit-scrollbar-track {
      background: #f1f1f1;
  }
  /* Style the scrollbar handle */
  ::-webkit-scrollbar-thumb {
      background: #888;
  }
  /* Style the scrollbar handle on hover */
  ::-webkit-scrollbar-thumb:hover {
      background: #555;
  }


  .history-div{
    padding: 5px 15px;

  }

  .history-href{
     margin: 3px 7px;  
  }



  .comment-content {
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #e9e9e973;
    margin-right: 21px;
  }


  
.modal-c {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content-c {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 300px;
    text-align: center;
  }
  
  .button-c {
    margin: 10px;
    padding: 10px 20px;
  }
  